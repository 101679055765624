<script lang="ts" setup>
const scrollToTop = () => {
  document.getElementById('page_logo')?.focus();
  window.scrollTo(0, 0);
};
</script>

<template>
  <div class="wrapper">
    <tw-ui-action
      is="link"
      class="button"
      title="Zum Seitenanfang"
      variant="button-circle"
      aria-label="Zum Seitenanfang"
      @click="scrollToTop"
      @keydown.enter.space.prevent="scrollToTop"
    >
      <Icon name="bi:arrow-up-short" alt="Zum Seitenanfang" class="icon" />
    </tw-ui-action>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';

.wrapper {
  z-index: 0;
  bottom: -1.5em;
  position: absolute;
  left: calc(50% - 1.5em);
}

:deep(.button) {
  padding: 0 !important;
}

.icon {
  width: 3rem;
  height: 3rem;
  color: $white;
}
</style>
